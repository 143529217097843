import { AppKeyFacetype } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'facetype-client';

export const FacetypeProjectMeta: ProjectMeta = {
  id: AppKeyFacetype,
  firebaseId: 'pixels-facetype',
  appName: 'facetype',
  folderName: FOLDER_NAME,
  supportPhases: [Phase.prod],
  port: 8119,
  appId: '',
  appStoreUrl: '',
  apiPort: 5100,
  noApi: true,
  testHostname: 'test-facetype',
  test: {
    apiUrl: 'https://test-admin-api.pixelteam.io',
    kakaoAppKey: '',
    googleReversedId: '',
    iosEncodingAppId: '',
    firebaseConfig: {
      apiKey: 'AIzaSyBvfOSilMhfJ1B03NbG5375U3_vGMK5554',
      authDomain: 'pixels-facetype-dev.firebaseapp.com',
      projectId: 'pixels-facetype-dev',
      storageBucket: 'pixels-facetype-dev.appspot.com',
      messagingSenderId: '804931109766',
      appId: '1:804931109766:web:9cfe2e0cd1298f20ae2207',
      measurementId: 'G-Y368TPS97Y'
    }
  },
  dev: {
    apiUrl: 'https://test-admin-api.pixelteam.io',
    kakaoAppKey: '',
    googleReversedId: '',
    iosEncodingAppId: '',
    firebaseConfig: {
      apiKey: 'AIzaSyBvfOSilMhfJ1B03NbG5375U3_vGMK5554',
      authDomain: 'pixels-facetype-dev.firebaseapp.com',
      projectId: 'pixels-facetype-dev',
      storageBucket: 'pixels-facetype-dev.appspot.com',
      messagingSenderId: '804931109766',
      appId: '1:804931109766:web:9cfe2e0cd1298f20ae2207',
      measurementId: 'G-Y368TPS97Y'
    }
  },
  prod: {
    apiUrl: 'https://admin-api.pixelteam.io',
    deployPath: '/api/v1/project/9070/stage/9974/scenario/11108/deploy',
    kakaoAppKey: '',
    googleReversedId: '',
    iosEncodingAppId: '',
    firebaseConfig: {
      apiKey: 'AIzaSyDtXs847kXnsTpxelgWECtAAAdLwDW5gfs',
      authDomain: 'pixels-facetype.firebaseapp.com',
      projectId: 'pixels-facetype',
      storageBucket: 'pixels-facetype.appspot.com',
      messagingSenderId: '68453614657',
      appId: '1:68453614657:web:ca86858fb2a03579a9f2c0',
      measurementId: 'G-40Q8F5Z7KL'
    }
  },
  consoleLogo: `
███████╗ █████╗  ██████╗███████╗████████╗██╗   ██╗██████╗ ███████╗
██╔════╝██╔══██╗██╔════╝██╔════╝╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝
█████╗  ███████║██║     █████╗     ██║    ╚████╔╝ ██████╔╝█████╗  
██╔══╝  ██╔══██║██║     ██╔══╝     ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  
██║     ██║  ██║╚██████╗███████╗   ██║      ██║   ██║     ███████╗
╚═╝     ╚═╝  ╚═╝ ╚═════╝╚══════╝   ╚═╝      ╚═╝   ╚═╝     ╚══════╝
`
};
