import { type AnalyticsSettings } from '@angular/fire/analytics';

export enum InstaChatProperty {
  location = 'location',
  birthYear = 'birthYear',
  gender = 'gender',
  imsoloName = 'imsoloName',
  mbti = 'mbti',
  artist = 'artist',
  countryCode = 'countryCode',
}

export const ANALYTICS_OPTIONS: AnalyticsSettings = {
  config: {
    send_page_view: false,
    cookie_flags: 'max-age=7200;secure;samesite=none',
    transport_type: 'beacon',
  }
};

export enum GAEventType {
  userAudit = 'userAudit',
  clickOpenChatsChatRoom = '오픈채팅_채팅방클릭',
  clickChattingsChatRoom = '채팅_채팅방클릭',
  clickProfileProfileImage = '프로필_프사이미지',
  clickProfileAddImage = '프로필_추가이미지',
  clickChatRoomProfileImage = '채팅방_프로필사진',
  profile_OneOnOneChatting = '프로필_1_1채팅',
  profile_BanUser = '채팅방_내보내기',
  chatRoom_clickAddPhoto = '채팅방_사진첨부',
  chatRoom_clickProfileViewButton = '채팅방_프로필보기버튼',
  profileEdit_clickEditButton = '프로필수정_수정버튼',
  myProfile_clickEditButton = '프로필_수정버튼',
  myProfile_clickSettingButton = '프로필_설정버튼',
  tabs_clickTab = 'tab_click',
  clickReceiveAlarm = '대기실_알림_알림받기',
  clickCloseAlarm = '대기실_알림_닫기',
  clickLoginAgreement = '로그인_약관동의',
  clickLoginKakao = '로그인_카카오',
  clickLoginApple = '로그인_애플',
  clickLoginGoogle = '로그인_구글',
  clickLoginPhone = '로그인_핸드폰',
  clickConnectPhoneSend = '연결_핸드폰_인증번호발송',
  clickLoginPhoneSend = '로그인_핸드폰_인증번호발송',

  // @see https://app.asana.com/0/1205505625269011/1207097408461562/f
  // 탭에 진입할 때 마다 이벤트 발생 (다른 탭 다녀와도 카운트 O)
  PageOpenChatTabListView = 'P_오픈채팅_탭_목록조회', // P_오픈채팅 탭 목록조회
  PageWaitingTabListView = 'P_대기실_탭_목록조회', //
  PageMyChatTabListView = 'P_내_채팅_탭_목록조회',
  PageApplyTabListView = 'P_신청_탭_목록조회',
  PageContentsTabListView = 'P_콘텐츠_탭_목록조회',
  PageProfileTabListView = 'P_프로필_탭_목록조회', // (내 프로필)
  PageBoardPostsTabListView = 'P_게시판_탭_목록조회',

  // 일반 지표
  PageOpenChatRoomCoverView = 'P_오픈채팅방_커버조회',
  PageOpenChatRoomJoin = 'P_오픈채팅방_신규_참여하기', //  (신규방 입장)
  PageOpenChatRoomView = 'P_오픈채팅방_조회하기', // (신규방 입장 + 기존방 조회)
  PageOneOnOneChatRoomView = 'P_1:1_채팅방_조회하기',
  PageChatRoomView = 'P_채팅방_조회하기', // (오픈채팅 or 1:1 채팅)
  PageOtherProfileView = 'P_타인_프로필_조회',

  // 게시글 관련
  BoardPageBoardPost = 'P_게시판_게시글_조회하기',
  BoardActionWrite = '게시글_작성',
  BoardActionModify = '게시글_수정',
  BoardActionDelete = '게시글_삭제',
  BoardActionReport = '게시글_신고',
  BoardActionCommentWrite = '게시글_댓글작성',
  ClickBoardPostItem = 'C_게시판_게시글클릭',
  ClickBoardPostsCategory = 'C_게시판_카테고리클릭',
  ClickBoardPostsWrite = 'C_게시판_글쓰기클릭',
  ClickBoardPostsLike = 'C_게시판_좋아요클릭',
  ClickBoardPostsComment = 'C_게시판_댓글클릭',
  ClickBoardPostViewCategory = 'C_게시글_카테고리클릭',
  ClickBoardPostViewLike = 'C_게시글_좋아요클릭',
  ClickBoardPostViewCommentLike = 'C_게시글_댓글좋아요클릭',

  AppliedOneOnOne = '친구신청_보냄',
  ReceivedOneOnOne = '친구신청_받음',
  ConnectedOneOnOne = '친구_맺어짐',
  SendMessage = '메시지_발신',
  ReceiveMessage = '메시지_수신',
  OpenChatCreated = '오픈채팅방_생성',
  오픈채팅방_삭제 = '오픈채팅방_삭제',

  // 세부 지표 (발생한 위치는 구분해서 보고 싶은)
  SendMessageAtOpenChatRoom = '메시지_발신_at_오픈채팅방',
  SendMessageAtOneOnOneChatRoom = '메시지_발신_at_1_1_채팅방',
  메시지_수신at오픈채팅방 = '메시지_수신_at_오픈채팅방',
  메시지_수신at1_1_채팅방 = '메시지_수신_at_1_1_채팅방',
  PageOtherProfileViewAtWaitingRoom = 'P_타인_프로필_조회_at_대기실',
  PageOtherProfileViewAtOneOnOneChatRoom = 'P_타인_프로필_조회_at_1_1_채팅방',
  PageOtherProfileViewAtOpenChatRoom = 'P_타인_프로필_조회_at_오픈채팅방',
  PageOtherProfileViewAtApplyTab = 'P_타인_프로필_조회_at_신청탭',
  ClickOtherProfileAtWaitingRoom = 'C_타인_프로필_조회_1_1_채팅_클릭_at_대기실',
  ClickOtherProfileAtOneOnOneChatRoom = 'C_타인_프로필_조회_1_1_채팅_클릭_at_1_1_채팅방',
  ClickOtherProfileAtOpenChatRoom = 'C_타인_프로필_조회_1_1_채팅_클릭_at_오픈채팅방',
  ClickOtherProfileAtBoardPost = 'C_타인_프로필_조회_1_1_채팅_클릭_at_게시글보기',
  ClickOtherProfileAtApplyTab = 'C_타인_프로필_조회_1_1_채팅_클릭_at_신청탭',

  ClickChatMessageTranslate = 'C_채팅방_메시지_번역',
  ClickChatMessageTranslateClose = 'C_채팅방_메시지_번역닫기',

  ClickChatMessageVideoChat = 'C_채팅방_통화말풍선',
  ClickProfileVideoChat = 'C_프로필_통화',
  ClickProfileChatRoomVideoChat = 'C_채팅방_통화',

  // AI 콘텐츠 지표
  P_콘텐츠_상세조회 = 'P_콘텐츠 상세조회',
  P_콘텐츠_얼굴선택 = 'P_콘텐츠 얼굴선택',
  P_콘텐츠_분석결과 = 'P_콘텐츠 분석결과',
}

export enum UserAuditType {
  deniedPush = 'deniedPush',
  deniedLocalNotification = 'deniedLocalNotification',
  invalidSmsCode = 'invalidSmsCode',
  sendTimeoutSms = 'sendTimeoutSms',
  loadErrorServiceWorker = 'loadErrorServiceWorker',
}
