import { FacetypeProjectMeta } from '@pixels/universal/model/apps/project-meta/facetype-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { PROD_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: FacetypeProjectMeta.prod?.apiUrl ?? '',
  phase: Phase.prod,
  firebase: FacetypeProjectMeta.prod?.firebaseConfig ?? {},
  talkPlusAppId: FacetypeProjectMeta.prod?.talkPlusAppId ?? '',
  vapidKey: FacetypeProjectMeta.prod?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: PROD_PROXY_URL,
  hostname: 'https://www.facetype.ai'
};
