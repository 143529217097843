import { isPlatformBrowser } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, PLATFORM_ID } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ANALYTICS_OPTIONS } from '@pixels/client/anayltics/analytics.model';
import { DEFAULT_LANGUAGE_TOKEN, SUPPORT_LANGUAGE_CODES_TOKEN } from '@pixels/client/i18n-util/i18n-util';
import { API_URL_TOKEN } from '@pixels/client/injection-tokens/api-url-token';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { HOSTNAME_TOKEN } from '@pixels/client/injection-tokens/hostname-token';
import { apiWrapperInterceptor } from '@pixels/client/interceptors/api-wrapper.interceptor';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { Lang } from '@pixels/universal/model/i18n/i18n.model';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { environment } from '../environments/environment';
import { AppI18nLoader } from './app-i18n-loader';

import { routes } from './app.routes';
import { FacetypeClientAppInfo } from './facetype-client-app-info';

const isIOS = Capacitor.getPlatform() === 'ios';
const isNative = Capacitor.isNativePlatform();

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular({ swipeBackEnabled: isIOS && isNative }),
    provideRouter(routes, withPreloading(QuicklinkStrategy)),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        apiWrapperInterceptor,
        // environmentInterceptor
      ])),
    provideFirebaseApp(_ => {
      const app = initializeApp(environment.firebase);
      if (isPlatformBrowser(inject(PLATFORM_ID))) {
        import('@angular/fire/analytics').then(({ initializeAnalytics }) => initializeAnalytics(app, ANALYTICS_OPTIONS));
      }
      return app;
    }),
    importProvidersFrom(
      QuicklinkModule,
      TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: AppI18nLoader } }),
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: SUPPORT_LANGUAGE_CODES_TOKEN, useValue: [Lang.EN, Lang.KO] },
    // { provide: LANGUAGE_BY_BROWSER_TOKEN, useValue: true },
    { provide: DEFAULT_LANGUAGE_TOKEN, useValue: Lang.EN },
    { provide: API_URL_TOKEN, useValue: environment.apiUrl },
    { provide: HOSTNAME_TOKEN, useValue: environment.hostname },
    { provide: PHASE_TOKEN, useValue: environment.phase },
    { provide: AppInfo, useClass: FacetypeClientAppInfo },
  ]
};
