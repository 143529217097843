import { Routes } from '@angular/router';
import { i18nGuard } from '@pixels/client/guards/i18n.guard';
import { getTitleByPhase } from '@pixels/client/phase/phase.util';
import { lazyLoadError } from '@pixels/client/router/lazy-load-error';
import { IChatParamKey } from '@pixels/universal/model/insta-chat/insta-chat-constant';
import { environment } from '../environments/environment';
import { facetypeRoutes } from './core/constant';

export const routes: Routes = [
  {
    path: '',
    title: () => getTitleByPhase(environment.phase, 'FACETYPE.AI'),
    canActivate: [i18nGuard],
    loadComponent: () => import('./feature/facetype/facetype.component').then((m) => m.FacetypeComponent).catch(lazyLoadError)
  },
  {
    path: facetypeRoutes.kpopPhoto.path,
    title: () => getTitleByPhase(environment.phase, 'KPOP PHOTO : FACETYPE.AI'),
    canActivateChild: [i18nGuard],
    loadChildren: () => import('./feature/kpop-photo/kpop-photo-routes').then((m) => m.kpopPhotoRoutes).catch(lazyLoadError)
  },
  {
    path: `static/:${IChatParamKey.contentName}`,
    loadComponent: () => import('@pixels/client/pages/static-viewer/static-viewer.page').then(m => m.StaticViewerPage).catch(lazyLoadError),
  },
  { path: '**', redirectTo: '/' }
];

