import { inject, Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import type {
  FirebaseAnalyticsPlugin,
  GetAppInstanceIdResult,
  IsEnabledResult,
  LogEventOptions,
  SetCurrentScreenOptions,
  SetUserPropertyOptions
} from '@capacitor-firebase/analytics';
import { globalCatchError } from '@pixels/client/app-injector/global-catch-error';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { Phase } from '@pixels/universal/model/phase-model';
import { concatMap, defer, forkJoin, map, Observable, Subject, switchMap, tap } from 'rxjs';

export type IAnalyticsService = Omit<InstanceType<typeof AnalyticsService>, 'logEventSource' | 'isNotProd'>;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private readonly logEventSource = new Subject<LogEventOptions>();
  private readonly isNotProd = inject(PHASE_TOKEN) !== Phase.prod;
  // provideFirebaseApp 을 사용한 방식의 경우, firebase init 하기 위해 필요한 코드
  private app = inject(FirebaseApp);

  constructor() {
    forkJoin([
      this.logEventSource.pipe(
        tap(v => this.isNotProd && console.log(`[GTAG] ${JSON.stringify(v)}`)),
        concatMap(v => this.logEvent(v)),
        globalCatchError()
      )
    ]).subscribe();
  }

  public getAppInstanceId(): Observable<GetAppInstanceIdResult> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.getAppInstanceId()));
  }

  public setUserId(userId: string | null): Observable<void> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.setUserId({ userId })));
  }

  public setUserProperty(options: SetUserPropertyOptions): Observable<void> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.setUserProperty(options)));
  }

  public setCurrentScreen(options: SetCurrentScreenOptions): Observable<void> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.setCurrentScreen(options)));
  }

  public logEvent(options: LogEventOptions): Observable<void> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.logEvent(options)));
  }

  public emitLogEvent(options: LogEventOptions): void {
    this.logEventSource.next(options);
  }

  public setEnabled(enabled: boolean): Observable<void> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.setEnabled({ enabled })));
  }

  public isEnabled(): Observable<IsEnabledResult> {
    return this.analytics().pipe(switchMap(FirebaseAnalytics => FirebaseAnalytics.isEnabled()));
  }

  private analytics(): Observable<FirebaseAnalyticsPlugin> {
    return defer(() => import('@capacitor-firebase/analytics')).pipe(map(({ FirebaseAnalytics: m }) => m));
  }
}

