import { isPlatformServer, registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import { Component, inject, Injector, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AnalyticsService } from '@pixels/client/anayltics/analytics.service';
import { AppInjector } from '@pixels/client/app-injector/app.injector';
import { globalCatchError } from '@pixels/client/app-injector/global-catch-error';
import { concatMap, defer, filter, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  // host: { ngSkipHydration: 'true' }
})
export class AppComponent implements OnInit {
  private readonly analytics = inject(AnalyticsService);
  private readonly router = inject(Router);
  private readonly platformId = inject(PLATFORM_ID);

  constructor(injector: Injector) {
    registerLocaleData(localeKo, 'ko');
    AppInjector.getInstance().setInjector(injector);
  }

  private get attachAnalytics$(): Observable<any> {
    return this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      concatMap(({ url }) => defer(() => import('@angular/fire/analytics')).pipe(
        switchMap(({ isSupported }) => isSupported()),
        switchMap(supported => supported ? this.analytics.setCurrentScreen({ screenName: url }) : of(undefined))
      )),
      globalCatchError()
    );
  }

  public ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.attachAnalytics$.subscribe();
  }
}
