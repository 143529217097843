import { bootstrapApplication } from '@angular/platform-browser';
import { initCapacitorAndAngular } from '@pixels/client/sentry/sentry-util';
import { PIXELS_CLIENT_DSN } from '@pixels/client/sentry/sentry.model';
import { isLocalHost } from '@pixels/client/utils/dev-tools';
import { FacetypeProjectMeta } from '@pixels/universal/model/apps/project-meta/facetype-project-meta';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import './console-message';
import { environment } from './environments/environment';

if (!isLocalHost() && environment.production) {
  initCapacitorAndAngular(PIXELS_CLIENT_DSN, `${environment.phase}-${FacetypeProjectMeta.folderName}`);
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
