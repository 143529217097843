import { Injectable, signal } from '@angular/core';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyFacetype } from '@pixels/universal/model/apps/app-types';
import { FacetypeProjectMeta } from '@pixels/universal/model/apps/project-meta/facetype-project-meta';

@Injectable({ providedIn: 'root' })
export class FacetypeClientAppInfo extends AppInfo {
  public readonly appType = AppKeyFacetype;
  public readonly serviceName = 'facetype';
  public readonly appStoreUrl = signal(FacetypeProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${FacetypeProjectMeta.appId}`);
  public override readonly adminMenus = [];
}
