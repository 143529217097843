import { inject, Injectable } from '@angular/core';
import { NgForage } from 'ngforage';
import { defer, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RxNgForage {
  private readonly ngf = inject(NgForage);

  // 함수 시그니처 정의
  public getItem<T>(key: string, defaultValue: T): Observable<T>;
  public getItem<T>(key: string): Observable<T | undefined>;

  // 구현체 정의
  public getItem<T>(key: string, defaultValue?: T): Observable<T | undefined> {
    return defer(() => this.ngf.getItem<T>(key)).pipe(
      map(item => item === null ? defaultValue : item)
    );
  }

  public setItem<T>(key: string, data: T): Observable<T> {
    return defer(() => this.ngf.setItem<T>(key, data));
  }

  public removeItem(key: string): Observable<void> {
    return defer(() => this.ngf.removeItem(key));
  }

  public keys(): Observable<string[]> {
    return defer(() => this.ngf.keys());
  }

  public hasItem(key: string): Observable<boolean> {
    return this.getItem(key).pipe(map(item => !!item));
  }
}

export type IRxNgForage = Omit<InstanceType<typeof RxNgForage>, 'ngf'>;
