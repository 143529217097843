import { Phase } from '@pixels/universal/model/phase-model';

export function getTitleByPhase(phase: Phase, title: string): string {
  switch (phase) {
    case Phase.dev:
      return `D-${title}`;
    case Phase.local:
      return `L-${title}`;
    default:
      return title;
  }
}
